import React from "react";
import "./styles/Home.css";

function Home() {
    return(
        <div id="/">
            <header className="header">
                <div class="introduction">
                    <h1 class="introduction__content">
                        <span class="introduction__content-el"> Colin Macon</span>
                    </h1>
                </div>
            </header>
        </div>
    );
}

export default Home;
