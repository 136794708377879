import React from "react";
import "./styles/ProfSummary.css";

function ProfSummary() {
    return(
        <div id="profsummary">
		<section className="profsummary">
			<div className="profsummary__wrapper wrapper--small">
				<div className="profsummary__content">
					<div className="profsummary__content-blurb blurb blurb--framed">
						<h2 className="blurb__heading">Professional Summary</h2>
						<p className="blurb__copy no-margin">Innovative and solutions-driven professional with a strong background in backend processes and automation.
                                                             Skilled at enhancing efficiency through creative problem-solving and strategic planning.
                                                             Passionate about leading and teaching, with a steadfast commitment to continuous learning and growth.
                                                             Ready to leverage my experience to drive impactful and sustainable results.
                        </p>
                    </div>
				</div>
            </div>
		</section>
        </div>
    );
}

export default ProfSummary;