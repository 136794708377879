import React, { useState } from "react";
import { Link } from 'react-scroll';
import "./styles/NavBar.css";
import menuIcon from './images/menu-icon.png';
import resumePDF from './Colin-Macon-Resume.pdf';

function NavBar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <nav className="navbar">
      <div className="nav-container">
        <div className="nav-icon" onClick={handleClick}>
          <img src={menuIcon} alt="Menu Icon" />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link 
              to="/" 
              spy={true} 
              smooth={true} 
              offset={-70} 
              duration={500}
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}>
              Home
            </Link>
          </li>

          <li className="nav-item">
            <Link 
              to="profsummary" 
              spy={true} 
              smooth={true} 
              offset={-70} 
              duration={500}
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}>
              Professional Summary
            </Link>
          </li>

          <li className="nav-item">
            <Link 
              to="edu" 
              spy={true} 
              smooth={true} 
              offset={-70} 
              duration={500}
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}>
              Education
            </Link>
          </li>

          <li className="nav-item">
            <Link 
              to="XP" 
              spy={true} 
              smooth={true} 
              offset={-70} 
              duration={500}
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}>
              Experience
            </Link>
          </li>

          <li className="nav-item">
            <Link 
              to="interests" 
              spy={true} 
              smooth={true} 
              offset={-70} 
              duration={500}
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}>
              Interests
            </Link>
          </li>

          <li className="nav-item">
            <Link 
              to="timeline" 
              spy={true} 
              smooth={true} 
              offset={-70} 
              duration={500}
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}>
              Achievements
            </Link>
          </li>
        </ul>
        <a className={click ? "nav-download-button active" : "nav-download-button"} href={resumePDF} download="Colin-Macon-Resume.pdf">Download Resume</a>
      </div>
    </nav>
  );
}

export default NavBar;