import React from 'react';
import "./styles/Interests.css";

function Interests() {
    return(
        <div id="interests">
            <h3 className="interests-heading">Interests</h3>
            <div className="interests-container">

                <div className="interest-item">
                    <div className="interest-year">
                        <p className="f2 heading--sanSerif">Health</p><br/>
                        <p className="f2 heading--sanSerif">&</p><br/>
                        <p className="f2 heading--sanSerif">Wellness</p>
                    </div>

                    <div className="interest-content">
                        <p>Focusing on fitness, mobility, and overall well-being is a key priority in my life.</p>
                    </div>
                </div>

                <div className="interest-item">
                    <div className="interest-year">
                        <p className="f2 heading--sanSerif"> Baseball</p><br/>
                        <p className="f2 heading--sanSerif">&</p><br/>
                        <p className="f2 heading--sanSerif">Racing</p>
                    </div>

                    <div className="interest-content">
                    <p>Engaged in sports performance data <br></br><br></br> Passionate about competitive motorsports</p>
                    </div>

                </div>

                <div className="interest-item">
                    <div className="interest-year">
                        <p className="f2 heading--sanSerif">Design</p>
                    </div>

                    <div className="interest-content">
                        <p>Passionate about creating visually appealing and user-friendly designs</p>
                    </div>
                </div>

                <div className="interest-item">
                    <div className="interest-year">
                        <p className="f2 heading--sanSerif">Automation</p>
                    </div>

                    <div className="interest-content">
                    <p>I utilize Python, SQL, R, React, and other technologies to automate data processes and projects</p>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default Interests;