import React from 'react';
import './styles/Edu.css';

function Edu () {
    return(
        <div id="edu" className="container">
            <h1 className="page-title">Education</h1>
            <div className="blog-card">
                <div className="meta">
                    <div className="phototwo"></div>
                    <ul className="details">
                        <li className="date"> Magna Cum Laude</li>
                        <li className="focus"> Focus - Strategy & MIS </li>
                        <li className="active">Credential - Data Programming </li> <br></br>
                        <li className="award">1st Place - Machine Learning Challenge (Python & R)</li>
                        <li className="active">Vice President - Adam Smith Society</li>
                    </ul>
                </div>
                <div className="description">
                    <h1>University of Pittsburgh</h1>
                    <h2>Master of Business Administration (MBA)</h2>
                </div>
            </div>
            <div className="blog-card alt">
                <div className="meta">
                    <div className="photo"></div>
                    <ul className="details">
                        <li className="ach-two">All Academic Selection Award</li>
                        <li className="sport"> Division 1 Varsity Baseball </li>
                        <li className="ach-one">Two-time Penn State Varsity S Club Award Recipient</li>
                    </ul>
                </div>
                <div className="description">
                    <h1>Penn State University </h1>
                    <h2>Economics (B.S.)</h2>
                </div>
            </div>
        </div>

    )
}

export default Edu;

