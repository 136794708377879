import React from 'react';
import './styles/Timeline.css';

// Function to recursively render achievements with nested details
const renderAchievements = (achievements, level = 0) => {
  return achievements.map((achievement, index) => (
    <div key={index} className={`timeline-item level-${level}`}>
      <h2 className={`achievement-title level-${level}`}>{achievement.title}</h2>
      <ul className={`achievement-details level-${level}`}>
        {achievement.details.map((detail, idx) => (
          typeof detail === 'string' ? <li key={idx}>{detail}</li> : <li key={idx}>{renderAchievements([detail], level + 1)}</li>
        ))}
      </ul>
    </div>
  ));
};

// Main component to display professional and personal achievements
const Timeline = () => {
  const professionalAchievements = [
    {
      title: "2023 - Present",
      details: [
        "Accepted a position at Trilliant, transitioning to remote work in NYC",
        "Relocated to Nashville for career advancement opportunities",
        "Featured in the Wall Street Journal within the first month",
        "Led a high-profile healthcare project as the sole analyst",
        "Contributed to annual report documentation and code repository maintenance",
        "Developed the Trilliant Health API Wrapper",
        "Led CMS certification project as the sole analyst",
        "Launched the Compass+ Oncology Dashboard, the team's inaugural dashboard product"
      ]
    },
    {
      title: "2020 - 2022",
      details: [
        "Served as a Decision Support Analyst at Highmark Inc.",
        "Optimized quarterly reporting processes and request intake procedures",
        "Transitioned data analysis workflows to Power BI",
        "Promoted to Decision Support Analyst",
        "Oversaw project management for dashboard meetings",
        "Graduated early with honors from an MBA program, despite pandemic challenges",
        "Won R and Python machine learning competitions",
        "Applied advanced XGBoost and parameter tuning techniques on voter data in R",
        "Developed a baseball pitch prediction model using XGBoost in Python"
      ]
    },
    {
      title: "2018 - 2020",
      details: [
        "Worked as an Assistant Financial Research Administrator at the University of Pittsburgh",
        "Pursued an MBA program concurrently with full-time employment",
        "Collaborated closely with the Medical Department's Director and Dean",
        "Developed an automated reconciliation system using Python",
        "Achieved first place in the Scaling Ethereum competition"
      ]
    }
  ];

  const personalAchievements = [
    {
      title: "Projects",
      details: [
        "Renovated a house, undertaking extensive home improvement projects",
        "Designed and implemented fully automated home systems",
        "Assembled and configured 4 node Beowulf cluster from old machines for various projects",
        "Developed and launched custom smart door project using YOLO v3 and OpenCV on Google Cloud",
        "Hosted personal website and repository documentation with NGINX and Docker",
        "Built a custom cloud storage solution using Seafile",
        "Trained pure-breed doberman for service dog certification",
        "Created a fully functional API wrapper and analysis tool for Whoop",
        "Engineered a customized fitness tracker to monitor health and wellness comprehensively",
        "Upgraded several 3D printers to improve production quality and reliability",
      ]
    },
    {
      title: "Creative Outlets",
      details: [
        "Produced highlight and clipped videos, recieving several million views in total",
        "Created artwork inspired by Roy Lichtenstein, under the guidance of art teacher Todd",
        "Repaired and upgraded several vehicles and motorcycles",
        "Developed and hosted multiple websites, practicing design and web development skills",
        "Designed and executed home remodel, showcasing creativity and craftsmanship",
      ]
    },
    {
      title: "Athletic Career Overview",
      details: [
        {
          title: "Penn State",
          details: [
            "Secured a spot on the baseball team as the only open try-out walk-on",
            "Set three weight room records, showcasing exceptional strength and conditioning",
            "Two time Varsity S Club award recipient",
            "Attracted interest from several MLB teams as a prospect"
          ]
        },
        {
          title: "Penn State Beaver",
          details: [
            "Participated in two national tournaments",
            "Won a conference championship",
            "Helped close friend and training partenr earn a scholarship to a Division I - Power 5 school"
          ]
        },
        {
          title: "Gannon University",
          details: [
            "Varsity baseball player",
            "Requested a redshirt to transfer to a Division I school, planning strategically for athletic career progression"
          ]
        }
      ]
    }
  ];

  return (
    <div className="timeline-container">
      <h1 className="timeline-title">Professional Achievements</h1>
      <div className="timeline">
        {renderAchievements(professionalAchievements)}
      </div>
      <h1 className="timeline-title">Personal Achievements</h1>
      <div className="timeline">
        {renderAchievements(personalAchievements)}
      </div>
    </div>
  );
};

export default Timeline;