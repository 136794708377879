import React from "react";
import "./styles/XP.css";

function XP() {
    return(
        <div id="XP" className="xp-container">

            <h1 className="xp-section-title">Work Experience</h1>
            
            <div className="xp-timeline-item" date-is='February 2023 - Present'>
                <h2 className="work-title">Data Analyst - Research</h2>
                <h4 className="company-name">Trilliant Health</h4>
                <p className="tasks">
                • Utilize PySpark and SQL to produce in-depth analyses tailored for clients, publications, and marketing initiatives<br></br>
                • Created data-driven products to enhance engagement and facilitate organizational growth<br></br>
                • Leverage Python to automate workflows and processes<br></br>
                • Showcased adaptability by assuming leadership roles and excelling under pressure<br></br>
                • Worked on various projects and daily tasks, supporting diverse teams and leveraging skills to meet their needs<br></br>
                </p>
            </div>
            
            <div className="xp-timeline-item" date-is='February 2020 - February 2023'>
                <h2 className="work-title">Decision Support Analyst</h2>
                <h4 className="company-name">Highmark Inc.</h4>
                <p className="tasks">
                • Developed and produced automated analytical reporting applications<br></br>
                • Managed full product life-cycle from development to production<br></br>
                • Ability to innovate and automate processes using Python<br></br>
                • Collaborated with cross-functional teams to achieve shared objectives<br></br>
                </p>
            </div>
            
            <div className="xp-timeline-item" date-is='November 2018 - January 2020'>
                <h2 className="work-title">Assistant Financial Research Administrator</h2>
                <h4 className="company-name">University of Pittsburgh</h4>
                <p className="tasks">
                • Primary account manager for multimillion-dollar International Partnership with NUSOM<br></br>
                • Created detailed budgets and financial models for various grant awards<br></br>
                • Review of legal contracts and faculty’s effort allocations<br></br>
                • Supported the Department of Medicine’s financial division<br></br>
                </p>
            </div>

        </div>
    );
}

export default XP;