import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import 'font-awesome/css/font-awesome.min.css';
import "./styles/Footer.css";

function Footer() {
    return (
        <div id="footer">
            <div className="social-container" >
                <a href="mailto:colin.macon1@gmail.com" className="email social">
                    <FontAwesomeIcon icon={faEnvelope}/>
                </a> 
                <a href="https://www.linkedin.com/in/colin-macon-b91a85165/" target="_blank" rel="noreferrer" className="linkedin social">
                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                </a>
                <a href="https://github.com/colinmacon" target="_blank" rel="noreferrer" className="github social">
                    <FontAwesomeIcon icon={faGithub} size="2x" />
                </a>
            </div>
            <div className="copyright-text">
            <p>Copyright © 2024 Colin Macon</p>
            </div>
        </div>
    )
}

export default Footer;

