import "./App.css";
import NavBar from "./components/NavBar";
import {
  BrowserRouter
} from "react-router-dom";

import Home  from "./components/Home";
import ProfSummary from "./components/ProfSummary";
import Edu from "./components/Edu";
import XP from "./components/XP";
import Interests from "./components/Interests";
import Timeline from "./components/Timeline";
import Footer from "./components/Footer"


function App() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <div>
          <Home />
          <ProfSummary />
          <Edu />
          <XP />
          <Interests />
          <Timeline />
          <Footer />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
